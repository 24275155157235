.teaser {
  &.cmp-teaser {
    &-center {
      text-align: center;

      .cmp-teaser {
        &-bloc {
          @media screen and (max-width: $tablet) {
            margin: auto;
          }
        }

        &-image,
        &-arrow-link {
          margin: auto;
        }

        ul {
          text-align: left;
        }

        &-title {
          padding-left: 0;
        }
      }
    }
  }
}
