.teaser {
  &.cmp-teaser {
    &-separator {
      .cmp-teaser {
        &-bloc:not(:not(:has(~ .cmp-teaser-bloc))) {
          border-right: 1px solid $borderInputColor;

          @media screen and (max-width: $tablet) {
            border: none;
          }

          &.image {
            &-right,
            &-left {
              border-bottom: 1px solid $borderInputColor;
              border-right: none;

              @media screen and (max-width: $tablet) {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
