@import '../../../../common/teaser/_teaser.scss';

.teaser {
  .cmp-teaser {
    &-content {

      .cmp-teaser {
        &-bloc {
          &.teaser-card {
            .cmp-teaser {
              &-card-content {
                position: relative;
              }

              &-image {
                position: relative;

                img {
                  border-radius: 0.5rem;
                  height: 18.75rem;
                  object-fit: cover;
                  width: 100%;
                }
              }

              &-overlay {
                background: linear-gradient(180deg, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0.9) 100%);
                border-radius: 0.5rem;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
              }

              &-text {
                color: $color-light;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2rem;
                position: absolute;
                bottom: 0;
                width: 100%;
              }

              &-textTitle {
                padding-bottom: 1rem;
              }

              &-label,
              &-bloctitle,
              &-subtitle,
              &-arrow-link {
                color: $color-light;
              }

              &-bloctitle {
                line-height: 1.5rem;
                padding: .5rem 0;
              }
            }
          }
        }
      }
    }
  }
}