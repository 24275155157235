.teaser {
  &.cmp-teaser {
    &-frame {
      .cmp-teaser {
        .cmp-teaser-content {
          .cmp-teaser {
            &-bloc,
            &-bloc.image-with-button {
              border: 1px solid $disabledColor;
              border-radius: 0.25rem;
              margin: 0.5rem;
              padding: 2rem 1rem;

              @media screen and (max-width: $tablet) {
                margin: 0.5rem auto;
              }
            }
          }
        }
      }
    }
  }
}
