.teaser {
  &.cmp-teaser {
    &-list {
      ul {
        padding: 0;

        li {
          background: url('../../../../../resources/images/icon-list.png') left top no-repeat;
          list-style: none;
          padding: 0 0 1.5rem 2.25rem;
        }
      }

      .teaser-banner {
        li {
          background: url('../../../../../resources/images/icon-list-white.png') left top no-repeat;
        }
      }
    }
  }
}
