@import '../../../site/variables';
@import './variations/_teaser_bg';
@import './variations/_teaser_center';
@import './variations/teaser_frame';
@import './variations/_teaser_separator';
@import './variations/teaser_list';

.teaser {
  padding: 3.125rem 0;

  @media screen and (max-width: $tablet) {
    padding: 1rem 0;
  }

  .cmp-teaser {
    &-title {
      color: $titleColor;
      font-family: $font-heading;
      font-size: 1.875rem;
      font-weight: 700;
      line-height: 2.75rem;
      padding-left: 1rem;
      padding-bottom: 1.875rem;

      @media screen and (max-width: $tablet) {
        margin: auto;
        width: 80%;
      }

      @media screen and (max-width: $mobile-medium) {
        padding-left: 0;
        width: 100%;
      }
    }

    &-content {
      display: flex;
      align-items: stretch;
      justify-content: center;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }

      .button {
        padding: 0.625rem 0;
      }

      .cmp-teaser {
        &-bloc {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          padding: 1rem;

          @media screen and (max-width: $tablet) {
            margin: auto;
            width: 80%;
          }

          @media screen and (max-width: $mobile-medium) {
            padding: 1rem 0;
            width: 100%;
          }

          .cmp-teaser {
            &-image {
              border-radius: 0.375rem;

              img {
                border-radius: 0.375rem;
              }
            }

            &-label {
              color: $textColor;
              text-transform: uppercase;
            }

            &-bloctitle {
              color: $titleColor;
              font-family: $font-page;
              font-weight: 700;
              padding-top: 1rem;
            }

            &-subtitle {
              color: $textColor;
              font-weight: 700;
              line-height: 1.5rem;
            }

            &-blocdescription {
              line-height: 1.5rem;

              b {
                font-weight: 700;
              }
            }

            &-arrow-link {
              text-decoration: none;
              margin: 0;
              padding-top: 0.25rem;
              width: fit-content;

              &:after {
                content: " \2192";
              }

              &:hover {
                text-decoration: underline;
              }
            }

            &-button {
              margin: auto;
              width: 60%;
          
              @media screen and (max-width: $tablet) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
