.teaser {
  &.cmp-teaser {
    &-bg {
      &-grey {
        background: #F8F8FD;
      }

      &-blue {
        background: $greenLightBgColor;
      }

      &-grey,
      &-blue {
        padding: 1rem;
      }
    }
  }
}

main {
  .cmp-container {
    .aem-Grid {
      .teaser {
        &.cmp-teaser {
          &-bg {
            &-grey,
            &-blue {
              margin: 3.125rem 0;
            }
          }
        }
      }
    }
  }
}
